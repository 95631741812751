
.conversationBtn{
    width: 18vw;
    height: 35px;
    border: 2px solid #e6e5e8;
    text-decoration: none;
    background-color: #242424;
    justify-content: center;
    margin-left: 12%;
    margin-right: 12%;
    border-width: 0px;
    outline: none;
    margin-top: 2%;
    margin-bottom: 2%;
    cursor: pointer;
    
}

.conversationBtn:hover{
    background-color: #111111;
    opacity:1
}

.sConversationBtn{
    width: 8vw;
    height: 30px;
    border-radius: 40px;
    border: 2px solid #e6e5e8;
    text-decoration: none;
    background-color: #242424;
    justify-content: center;
    margin-left: 7px;
    margin-right: 10px;
    border-width: 0px;
    outline: none;
    margin-top: 2%;
    margin-bottom: 2%;
    cursor: pointer;  
}
.sContainer{

    display: flex;
    position: absolute;
    bottom: 16vh;
    left: 45%;
    transform: translateX(-50%);
}


.mobileContainer{
    
    width: 45%;
    bottom: 5%;
    /* right: 1%; */
   left:55%;
   transform:translateX(-50%);
    position: absolute;
    border-radius: 10px;
}

.container{
    width: 50%;
    bottom: 12%;
    /* right: 1%; */
   left:55%;
   transform:translateX(-50%);
    position: absolute;
    border-radius: 10px;
    
}

.btnContainer{
    display: flex;
    margin-left: 30%;
    margin-right: 30%;
    background-color: #242424;
    animation: .55s ease-in-out 0s normal forwards;
    border-radius: 0.3rem;
    opacity: 0.8;
    transform:translateX(-20%)
}
.mobileBtnContainer{
    display: flex;
    margin-left: 18%;
    margin-right: 30%;
    background-color: #242424;
    animation: .55s ease-in-out 0s normal forwards;
    border-radius: 0.3rem;
    opacity: 0.8;
    transform:translateX(-20%)
}
.sBtnContainer{
    width: 100%;
    display: flex;
    margin-left: 18%;
    margin-right: 25%;
    background-color: #242424;
    animation: .55s ease-in-out 0s normal forwards;
    border-radius: 0.3rem;
    opacity: 0.8;

    
}

.icon{
    width: 100%;
    height: 60%;
    fill: #ffffff;
    background-color: none;
    opacity: 1;
}

.icon{
    width: 100%;
    height: 60%;
    fill: #ffffff;
    background-color: none;
    opacity: 1;
}

.mobileIcon{
    width: 20px;
    height:20px;
    fill: #ffffff;
    background-color: none;
    opacity: 1;
}

.sIcon{
    width: 80%;
    height:100%;
    fill: #ffffff;
    background-color: none;
    opacity: 1;
}