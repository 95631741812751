input:focus,
textarea:focus {
  color: #fefefe;
}
input:hover {
  color: #fefefe;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fefefe;
  text-align: center;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #fefefe;
  text-align: center;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #fefefe;
  text-align: center;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #fefefe;
  text-align: center;
}
.center {
  position: absolute;
  top: 45%;
  left: 55%;
  transform: translate("-50%", "-50%");
}

.centerLocal {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate("-50%", "-50%");
}
.dashboard_container {
  overflow: hidden;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 1;
  -moz-overflow: hidden;
}

.dashboard_left_section {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  /*   border: 1px black solid; */
}

class_name {
  content: "\A";
  white-space: pre;
  display: block;
  width: 120%;
}

.dashboard_right_section {
  width: 20%;
  height: 100%;
  
}

.dashboard_content_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
}

.dashboard_rooms_container {
  width: 120%;
  height: 50px;
  display: flex;
  align-items: center;
  bottom: 0;
}

.connected {
  position: absolute;
  left: 2px;
}
.dashboard_info_text_container {
  margin-left: 80px;
  display: flex;
  flex-direction: column;
  margin-bottom: 13%;
}

.dashboard_info_text_title {
  font-size: 50px;
}

.dashboard_text_title {
  font-size: 20px;
}

.dashboard_info_text_description {
  margin-top: 20px;
  font-size: 20px;
}

.dashboard_active_users_list {
  height: 80%;
}

.dashboard_logo_container {
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.dashboard_logo_image {
  width: 60%;
}
/*************/

#drawersContainer {
  width: 20%; /*dont touch*/

  height: 89%;
  position: relative;
  top: 45.8px;
  left: 0;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  -moz-overflow: hidden;
  /* 	border: 1px black solid; */
  pointer-events: none;
}

.drawer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  pointer-events: none;
}
.drawer * {
  pointer-events: auto;
}
.drawer > input[type="checkbox"] {
  display: none;
}
.drawerPull {
  display: inline-block;
  width: 10%;
  height: 30px;
  background: DarkGray;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  vertical-align: top;
}

.drawerContents {
  width: 110%;
  position: absolute;
  top: 0;
  height: 100%;
}
.drawerPull {
  position: absolute;
  top: 0;
}
.drawerPull:after {
  content: "";
}

#rightDrawer {
  /* 	right: -300px; */
  right: -90%;
}
#rightDrawerContents {
  background-color: #dbe8f0;
  border-radius: 25px;

  transition-property: right;
  transition-duration: 0.5s;
  transition-function: ease linear;
}
#rightDrawerPull {
  right: 90%;
  position: absolute;
  transition-property: right;
  transition-duration: 0.5s;
  transition-function: ease linear;
}
#rightDrawerPull:after {
  content: "\2039";
}
#rightDrawerCheck:checked + #rightDrawerContents {
  right: 75%;
}
#rightDrawerCheck:checked + #rightDrawerContents + #rightDrawerPull {
  right: 120%;
}
#rightDrawerCheck:checked + #rightDrawerContents + #rightDrawerPull:after {
  content: "\203a";
}
.ActiveButton {
  top: 56%;
  height: 25px;
  border-radius: 50px;
  outline: none;
  border: 1px solid #e6e5e8;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  width: 140px;
  transition: 0.3s;
  position: absolute;
  left: 50px;
  bottom: 21%;
  background-color: #282c34;
  color: #e6e5e8;
  margin-left: 30px;
}

.ActiveButton:hover {
  background-color: #e6e5e8;
  color: #282c34;
  border: 1px solid #282c34;
  /*   opacity: 0.5; */
}

.welcome-thumb {
  position: absolute;

  top: 44.3%;
  left: 56%;
  transform: translate(10%, -50%);

  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.welcome-thumb img {
  height: auto;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.4);
  width: var(--button-size);
  height: var(--button-size);
  /*   background-color: #484a4d; */
  border-radius: 50%;
  padding: 5px;
  margin: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}

/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 58px;
  width: 300px;
  transform: translateX(-45%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
  -moz-overflow: hidden;
}

.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

.forgot-password,
.forgot-password a {
  margin: 0;
}

.forgot-password a {
  color: #1976d2;
}
.overflow{
overflow-y: hidden;
}