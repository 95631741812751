html {
  height: 100%;
}

body {
  min-height: 100%;
  width: 100%;
  color: #e6e5e8;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Rubik",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background_main_color {
  background-color: #EEF5F9;
  z-index:0;
}
.background_secondary_color {
  background-color: #EEF5F9;
}

.text_main_color {
  color: #fcfcfc;
}

.text_secondary_color {
  color: #0088ff;
}
