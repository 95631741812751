$sidebar-bg-color: #DBE8F0 !default;
$sidebar-color: #adadad !default;
$sidebar-width: matches && 18vw !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: white !default;
$submenu-bg-color: #FFFFFF !default;
$submenu-bg-color-collapsed: #2b2b2b  !default;
$icon-bg-color: #0088ff!default;
$icon-size: 35px !default;
$submenu-indent: 24px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;
@import '~react-pro-sidebar/dist/scss/styles.scss';



  