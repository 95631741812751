input:focus,
  textarea:focus {
    color: #666666;
  }
  input:hover {
    color: #666666;
  }
  
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #2c748b;
    text-align: center;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #7f8283;
    text-align: center;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #7f8283;
    text-align: center;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #7f8283;
    text-align: center;
  }
  
.login-page_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;

 
}

.login-page_login_box {
  width: 400px;
  height: 70%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transform: translateX(-115%);
  background-color: #FFFFFF;

}
.submitButton{transform: translate(0px,-40px);}



.login-page_signup_box {
  position:relative;

  width: 40%;
  height: 85%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
 /*  transform: translateX(-115%); */
  background-color: #FFFFFF;
}

.login-page_logo_container {
  width: 80px;
  display: flex;
  justify-content: center;
  
 
}

.login-page_copyright_logo {



  transform: translate(0px, 110px); 
}

.login-page_second_logo_image {
  width:40%;
height: 50px;
left:"50%";
 position:absolute;
transform:"translateX(-50%)";
 bottom:50px;


}


.login-page_second_logo_container {
 
  display: flex;
  justify-content: center;

  transform: translate(50%, 34px); 
}


.login-page_logo_image {
  width: 50%; 
  height: 10%;
  margin-top: 5px;
/*    border: 1px solid #282c34; */
   margin: 0;
   padding: 0;
}

.background{
  background: url("../resources/background.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

 
}

.signup-page_title_container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 10px;
  margin: 0;
  padding: 0;
  
}

.login-page_title_container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 10px;
  margin: 0;
  padding: 0;
  
}

.login-page_input_container {
 width:90%; 
  /* display: flex; */
  /* position: relative; */
 /*  justify-content: center; */
 display: block;
  	margin-left: auto;
	margin-right: auto;


}
.signup-page_input_container {
  /*   width:90%; */
    /* display: flex; */
    /* position: relative; */
   /*  justify-content: center; */
   transform:translate(0, -8px);
  }

.login-page_input {
   width: 110%;
   height:100%;
  border-radius: 10px;
/*   height: 25px; */
 /*  outline: none;
  border: none; */
  font-size: 16px;
 /*  font-weight: 500; */
  padding: 10px 10px;
/*   background-color: #e3edf3; */
  color: #0c80ce; 
}
input {
  color: red;
}
.login-page_button_container {
  width: 75%;
  transform: translate(0px,-42px);
  align-items: center;
  justify-content: center;
  color: #0c80ce;
  display: block;
  	margin-left: auto;
	margin-right: auto;
}

.signup-page_button_container {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0c80ce;
  transform: translate(0px,-25px);
}
.login-page_button {
  border-radius: 50px;
  outline: none;
  border: 1px solid #e6e5e8;
  font-size: 16px;
  padding: 10px 10px;
  width: 250px;
  transition: 0.3s;
  background-color: #0c80ce;
}

.login-page_button:hover {
  border: 1px solid #0c80ce;
  background-color: #e6e5e8;
  color: #0c80ce;
}


.signup-page_button {
  border-radius: 50px;
  outline: none;
  border: 1px solid #e6e5e8;
  font-size: 16px;
  padding: 10px 10px;
  width: 250px;
  transition: 0.3s;
  background-color: #0c80ce;
}

.signup-page_button:hover {
  border: 1px solid #0c80ce;
  background-color: #e6e5e8;
  color: #0c80ce;
}

.input-error {
  border-color: #e63946;
}

.error {
  color:#e63946;
  font-size: 1rem;
  margin-top: 0.3rem;
}
.thumbnail{
  width:10%;
   height: 10%;
   max-height: 40px;
   display: flex;
   flex: 0.4;
 }
 img {
   width: auto;
   height: 100%;
 }
 
 .Name{
   font-size: 15px;
   color: black;
   margin-left: 10px;
   flex: 2;
   display: flex;
 }