.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
}
body {
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
.pro-sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pro-sidebar {
  -ms-overflow-style: none; /* IE and Edge */

  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
.pro-sidebar {
  height: 97%;
  right: 83%;
  top: 61px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

input:focus,
textarea:focus {
  color: #fefefe;
}
input:hover {
  color: #fefefe;
}

.thumbnail {
  width: 10%;
  height: 10%;
  max-height: 40px;
  display: flex;
  flex: 0.4;
}
img {
  width: auto;
  height: 100%;
}

.Name {
  font-size: 15px;
  color: black;
  margin-left: 10px;
  flex: 2;
  display: flex;
}

#menu-item {
  background: #f8f6f6;
  color: black;
}
#menu-item:focus,
#menu-item:hover {
  background: #efefef;
}

#icon {
  color: #efefef;
}
#icon:focus,
#icon:hover {
  color: darkGray;
}

#iconFill {
  color: grey;
}
#iconFill:focus,
#iconFill:hover {
  color: rgb(70, 70, 70);
}
