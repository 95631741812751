body {
  margin: 0;
  background: #EEF5F9;
  font-family: roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bg: #0088ff;
  --bg-accent: #0088ff;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #0088ff;
  --border-radius: 8px;
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
  width: 100vw;
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

/* <li> */
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/*nav-icon-button*/
.nav-icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  /*  background-color: #484a4d; */
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.nav-icon-button:hover {
  filter: brightness(1.2);
}

.nav-icon-button svg {
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}
/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  /*  background-color: #484a4d; */
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: #656A6E;
  width: 20px;
  height: 20px;
}


/* Icon Button Add Friend */
.icon-button-add {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  /*  background-color: #484a4d;  */
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex; 
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
  left:233px;
  top:5px;
  position:absolute
}

.icon-button-add:hover {
  filter: brightness(1.2);

}

.icon-button-add svg {
  fill:#656A6E;
  width: 20px;
  height: 20px;
}




/* Icon Button Notification */

.icon-button-notification {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  /*  background-color: #484a4d; */
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button-notification:hover {
  filter: brightness(1.2);
}

.icon-button-notification svg {
  fill: #656A6E;
  width: 20px;
  height: 20px;
}




/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 58px;
  width: 300px;
  transform: translateX(-45%);
 /*  background-color: var(--bg); */
 background-color:#DBE8F0;
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
  -moz-overflow: hidden;
}

.menu {
  
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  color:black;
}

.menu-item .icon-button {
  margin-right: 0.5rem;

}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #E6EBF5;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit {
}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}
